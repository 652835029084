import React from "react"

import { Image } from "@components/image"

import * as styles from "./memberships-logos.module.css"

const MembershipsLogos = () => {
  return (
    <div className={styles.container}>
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/ukas-iso-9001.jpg?tx=w_160,h_120`}
        alt="UKAS Accredited Logo"
        width="160"
        height="120"
        loading="lazy"
      />
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/feefo-award-2024.jpg?tx=w_120,h_120`}
        alt="Feefo Award 2024"
        width="120"
        height="120"
        loading="lazy"
      />
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/LPCB.jpg?tx=w_127,h_120`}
        alt="LPCB Logo"
        width="127"
        height="120"
        loading="lazy"
      />
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/SBD_PPS.jpg?tx=w_185,h_120`}
        alt="SBD PPS Logo"
        width="185"
        height="120"
        loading="lazy"
      />
      <Image
        src={`https://first-fence-ltd.mo.cloudinary.net/pages/footer/RoSPA-Member.jpg?tx=w_160,h_78`}
        alt="RoSPA Member Logo"
        width="160"
        height="78"
        loading="lazy"
      />
    </div>
  )
}

export default MembershipsLogos
